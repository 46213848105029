export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #ACF600',
    padding: '16px',
    color: '#fff',
    background: '#000',
    
    
}

export const iconTheme = {
    primary: '#ACF600',
    secondary: '#000',
}

export const  position= {
    position: "bottom-center"
}