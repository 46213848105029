import React, { useState,useEffect } from 'react';
import {Button, Modal, InputGroup,Dropdown, Form, ProgressBar} from 'react-bootstrap'
import { getAccount } from '../../Hooks/useAccounts';
import { approveContract, BuyToken, checkIsApproved, Claim, toFixedNumber, UseBuyToken, UsegetSaleInfo, UsergetToken } from '../../Hooks/useContract';


const Masp = (props) => {
    const[masp, setMasp] = useState(true)
    const [LaunchpadData,setLaunchpadData] = useState({})
    const [presaleaddress,setpresaleaddress] = useState(props?.presaleaddress)
    const [accountInfo, setAccountInfo] = useState(getAccount());
    const [isMax, setIsMax] = useState(false);
    const [Maxdeposit, setmaxDeposit] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [isApproved , setIsApproved] = useState(false); 
    const [coinname,setcoinname]  = useState("USDT")
    const [userWillget,setuserWillget] = useState(0)
    const [progressBNB,setprogressBNB] = useState(0)
    const [progressUSDT,setprogressUSDT] = useState(0)
    const [presaletoken,setpresaletoken] = useState(0)
    const [allowance,setallowance] = useState(0)
    const [tokensymbol,settokensymbol] = useState("")
    const [maxBalance,setmaxBalance] = useState(0)
    const [claimbvalue,setclaimbvalue] = useState(0)

    useEffect(() =>{
       
        setpresaleaddress(getAccount())
        getData(props?.presaleaddress)
       
    },[presaleaddress])



    const getData =async(value) =>{
        try{
        var data = presaleaddress && value != "" ? await UsegetSaleInfo(value,accountInfo) : "";
        let claimbdatavalue
        approveFunction(data?.usdtaddress,props?.presaleaddress)
        setLaunchpadData(data)
        var claimbdata = toFixedNumber (parseFloat(data?.claimb) / 10 **5);
        claimbdatavalue = parseFloat(claimbdata) * 10 ** 18;
        console.log("claimbdatavalue",claimbdatavalue);
        setclaimbvalue(Math.round(parseFloat(claimbdatavalue)))
        var progressbnb = (parseInt(data?.bnbearnedcap)  / (parseInt(data?.hardCap)) *100).toFixed(2);
        var progressUSDT = (parseInt(data?.busdearnedcap) / (parseFloat(data?.busdhard))*100).toFixed(2);
        setprogressBNB(progressbnb)
        setprogressUSDT(progressUSDT)
        setpresaletoken(data?.presaletoken)
        setallowance(data?.allowance)
        settokensymbol(data?.presaletokensymbol)
        }catch(e){
            console.log("getData",e);
        }
    }

    // const maxDeposit = () => {
    //     if(coinname === "USDT") {
    //     if (parseFloat(LaunchpadData && LaunchpadData?.UserusdttokenBalance) > 0) {
    //       setIsMax(true);
    //       handleChange(LaunchpadData && LaunchpadData?.UserusdttokenBalance)
    //       setmaxDeposit(LaunchpadData && LaunchpadData?.UserusdttokenBalance);
    //     }
    //   }else{
   
    //     if (parseFloat(LaunchpadData && LaunchpadData?.UserBNB) > 0) {
    //         setIsMax(true);
    //         handleChange(LaunchpadData && LaunchpadData?.UserBNB)
    //         setmaxDeposit(LaunchpadData && LaunchpadData?.UserBNB);
    //       }

    //   }

    // }


    
    const maxDeposit = () => {
       
        if (parseFloat(LaunchpadData && LaunchpadData?.UserusdttokenBalance) > 0) {
          setIsMax(true);
          handleChange(LaunchpadData && LaunchpadData?.UserusdttokenBalance / 10 ** 18)
          setmaxDeposit(LaunchpadData && LaunchpadData?.UserusdttokenBalance / 10 ** 18);
          setmaxBalance(LaunchpadData && LaunchpadData?.UserusdttokenBalance)
         
          
        }
     

    }
      const approveFunction = async (value) => {
           const approval = await checkIsApproved(accountInfo,value,props?.presaleaddress);
             setIsApproved(approval);
         
        
    }
   
    const approve = async () => {
        if(accountInfo){
           
            await approveContract(accountInfo,LaunchpadData && LaunchpadData?.usdtaddress,props?.presaleaddress);
            setIsApproved(true);
        }
       
    }


const handleChange = async(data) => {
         var price = await UsergetToken(data,coinname,LaunchpadData?.presaletoken,props?.presaleaddress);
         setuserWillget(price)
   
    }

 const claimToken = async() => {
            
            await Claim(accountInfo,props?.presaleaddress)
                .then( async(result) => {
                 getData(props?.presaleaddress);  
               })
               .catch(e=>{
                  console.log("Error");
               })
}

const buyToken = async() =>{
   
    var data = await UseBuyToken(coinname,deposit > 0 ? deposit : maxBalance ,presaletoken,props?.presaleaddress,accountInfo,isMax)
   
         getData(props?.presaleaddress);  
     

}
        //   coinname, useramount, token, presaleAddress, account

    return(
        <>
         <Modal show={masp} centered size="xl">
            <Modal.Header className='pb-0'>
            <h5 className=''> 
            {/* {tokensymbol} */}
            AI-R </h5>
            <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            </Modal.Header>
            <Modal.Body className='py-4'>
                <div className='d-lg-flex gap-5 flex-1 my-4'>
                {/* <div className='mb-4'>
                        <div className='d-flex jc-between'>
                            <p> Progress ({LaunchpadData && LaunchpadData.bnbearnedcap || 0} / {LaunchpadData && LaunchpadData?.hardCap || 0} BNB ) </p>
                            <p>{progressBNB || 0} % </p>
                        </div>
                        <ProgressBar now={((parseInt(LaunchpadData && LaunchpadData?.bnbearnedcap)/parseInt(LaunchpadData && LaunchpadData?.hardCap))*100)} />
                    </div> */}
                    <div className='mb-4'>
                        <div className='d-flex jc-between'>
                            <p> Progress ({LaunchpadData && LaunchpadData.busdearnedcap || 0} / {LaunchpadData && LaunchpadData?.busdhard || 0} USDT ) </p>
                            <p>{progressUSDT || 0} %</p>
                        </div>
                        <ProgressBar now={((parseInt(LaunchpadData && LaunchpadData?.busdearnedcap)/parseInt(LaunchpadData && LaunchpadData?.busdhard))*100)} />
                    </div>
                </div>
                
                <div className='yellowbox text-center p-4 my-4'>
                    <div className='d-lg-flex jc-between align-items-center  flex-1'>
                        <div className='d-md-flex justify-content-lg-around gap-3 align-items-center w-full '>
                            <div className='text-start'>
                                <p className='themeclr mb-1 normal'>Your Balance</p>
                                {/* <h6 className='bold'>{LaunchpadData && parseFloat(LaunchpadData?.UserBNB).toFixed(3) || 0 } BNB & {LaunchpadData && parseFloat(LaunchpadData?.UserusdttokenBalance).toFixed(3) || 0 } USDT </h6> */}
                                <h6 className='bold'>{LaunchpadData && parseFloat(LaunchpadData?.UserusdttokenBalance / 10 ** 18).toFixed(3) || 0 } USDT </h6>

                            </div>
                            <div className='text-start'>
                                <p className='themeclr mb-1 normal'>Your Minimum Contribution</p>
                                {/* <h6 className='bold'>{LaunchpadData && LaunchpadData?.minamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdminamount || 0} USDT</h6> */}
                                <h6 className='bold'> {LaunchpadData && LaunchpadData?.busdminamount || 0} USDT</h6>

                            </div>
                        </div>
                        <div className='d-md-flex justify-content-lg-around gap-3 align-items-center w-full '>
                            <div className='text-start'>
                                <p className='themeclr mb-1 normal'>Your Maximum Contribution</p>
                                {/* <h6 className='bold'>{LaunchpadData && LaunchpadData?.maxamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdmaxamount ||0 } USDT</h6> */}

                                <h6 className='bold'> {Math.round(LaunchpadData && LaunchpadData?.busdmaxamount) ||0 } USDT</h6>
                            </div>
                            <div className='text-start'>
                                <p className='themeclr mb-1 normal'>Your Contribution</p>
                                {/* <h6 className='bold'>{LaunchpadData && LaunchpadData?.investedT || 0} BNB & {LaunchpadData && LaunchpadData?.busdinvestedT ||0 } USDT</h6> */}

                                <h6 className='bold'> {parseFloat(LaunchpadData && LaunchpadData?.busdinvestedT).toFixed(6) ||0 } USDT</h6>
                            </div>
                            <div className='text-start'>
                                <p className='themeclr mb-1 normal'>You will Get</p>
                                <h6 className='bold'>{userWillget || 0}   {tokensymbol} 
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
              <div className='d-lg-flex gap-5 flex-1 mt-4'>
                <div className='mb-4'>
                    <InputGroup className="mb-3">
                        <Form.Control aria-label="Amount (to the nearest dollar)" 
                      placeholder='Enter Amount'
                      onChange={(e) => {setDeposit(e.currentTarget.value); setIsMax(false);handleChange(e.currentTarget.value)}} 
                      value={isMax ? Maxdeposit : deposit}
                      className='form_text_white'
                   
                      />
                       
                          
                        <InputGroup.Text>
                        
                        
                        <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic" className='chocobtn text-dark nounder'>
        {coinname}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item onClick={()=>{setcoinname('BNB')}}>BNB</Dropdown.Item> */}
        <Dropdown.Item onClick={()=>{setcoinname('USDT')}}> USDT</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </InputGroup.Text> 
                    </InputGroup>
                    <div className='d-flex justify-content-start gap-4 mt-4'>
                    {isApproved ? 
                <button className='btn getstarted' onClick={() => { setIsMax(true); maxDeposit()}}>Max</button> : <></>}
                {isApproved ? 
                
                <button className='btn getstarted' onClick={() => buyToken()}>Buy</button> :
                <button className='btn getstarted' onClick={() => approve()}>Approve</button>}
            </div>
                </div>
                <div className='mb-4'>
                        <InputGroup className="mb-3">
                        <Form.Control value={claimbvalue} aria-label="Amount (to the nearest dollar)" disabled placeholder='0'  className='form_text_white'/>
                        <InputGroup.Text className='themeclr'>
                            {tokensymbol} 
                            </InputGroup.Text>
                    </InputGroup>
                    <div className='d-flex justify-content-start  mt-4'>
                       
                        {claimbvalue && parseFloat(claimbvalue) > 0 ?
                <button className='btn getstarted'onClick={()=>claimToken()}>Claim</button> :
                <button disabled className='btn getstarted'>Claim</button>}
            </div>
                    </div>
                    
              </div>
           
            </Modal.Body>
         
        </Modal>
        </>
    )
}

export default Masp