import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import newdetail from "../Assets/images/news.jpg";
// import Token from "../Assets/images/token.jpg"
import Token from "../Assets/images/newdetail.jpg"
import Token1 from "../Assets/images/newdetail1.jpg"
import newsimg from '../Assets/images/newsimg.png'
import { getArticles, getNewsData, getNewsDesData, getTotalArticleImage } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';
import banner_grad from "../Assets/images/banner_grad.png"
import bannerimg from '../Assets/images/newsbanner.png'
// import Token1 from "../Assets/images/token1.jpg"
const News = () => {


    const [newsdata, setnewsdata] = useState([])
    const [newsdesdata, setnewsdesdata] = useState([])
    const [articledata, setarticledata] = useState([])
    const responsive2 = {
        0: {
            items: 1,
        },
        424: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1.5,
            margin:50
        },
    }

    const responsive3 = {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }

    useEffect(() => {
        getData()
    }, []
    )

    const getData = async () => {

        const news = await getNewsData();
        setnewsdata(news)

        const newsdes = await getNewsDesData();
        setnewsdesdata(newsdes)


        const articles = await getArticles();

        // setarticledata(articles)
        const Imagedata = await getTotalArticleImage();
        var datsd = articles.map((it, index) => {
            it.image = Imagedata[index]

            return it;
        })


        setarticledata(datsd)


    }
    return (
        <div className="news">
            <Header />
            <div className='newstoprow'>

                <div>  <h1 className='innerheading'>News</h1>
                    <p className='innerdesc'>Empowering access to public web data. Join AI-R Community
                        and contribute to a more transparent Internet.</p></div>
                <img src={banner_grad} alt='banner_grad' className='img-fluid banner_grad' />

            </div>
            <div className='newshead'>

                <OwlCarousel className="owl-theme topcarousel" loop={true} nav={false}  autoplay={true} responsive={responsive2} dots={false}>
                    {newsdata && newsdata.map((item) =>
                    (item.status == true ?
                        <div className='items'>
                            <div className='cardstyle'>
                                <img src={backurl + `/Images/${item?.logoURI}`} alt='bannerimg' className='img-fluid cardimg' />
                                <div className='innercard'>
                                    <h2>{item?.Heading}</h2>
                                    <p>{item?.Description}</p>

                                </div>

                            </div>

                          
                        </div> : <></>)
                    )}
                </OwlCarousel>
               
            </div>
            <div className='Editor_pick firstsec'>
                <div className='container container-theme mt-4'>
                    <h2 className='sec_head'>Editor's pick</h2>
                    <div className='row'>
                      
                        {newsdesdata && newsdesdata.map((item) =>
                        (item.status == true ?
                            <div className='col-md-4 col-sm-6 col-12 col_token mb-4'>

                                <a href={`/newsdetails/${item._id}`}>
                            
                                    <div className='token_list'>
                                        <div className='sec_border'>
                                            <div className='tokenimg'>
                                                <img src={backurl + `/Images/${item?.logoURI}`} />
                                            </div>
                                            <div className='token_items'>
                                                <h2>{item?.Title}</h2>
                                                <p>{item?.Heading}</p>
                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div> : <></>)


                        )}
                    </div>

                </div>
            </div>

            <section className='Editor_pick launchpad' id='latest_article'>
                <div className='container container-theme mt-4'>
                    <h2 className='sec_head'>Latest Articles</h2>


                </div>
                <div className='container carouselcontainer'>
                    {articledata && articledata.length > 0 &&
                        <OwlCarousel items={3} className="owl-theme latestarticle" loop={false} nav={true} margin={10} autoplay={false} responsive={responsive3} dots={false}>
                            {articledata && articledata.map((item) =>
                                item.status == true ?
                                    <a href={`/latestarticles/${item && item?._id}`} className="col_token">
                                    <div className='token_list '>
                                            <div className='sec_border'>
                                                <div className='tokenimg'>
                                                    <img src={backurl + `/Images/${item && item?.logoURI}`}/>
                                                    {/* <img src={newsimg} /> */}
                                                </div>
                                                <div className='token_items'>
                                                    <h2>{item && item?.Title}</h2>
                                                    <p>{item && item?.Heading}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a> : <></>
                            )}
                        </OwlCarousel>

                    }





              
                </div>



            </section>
            <Footer />
        </div>
    )
}

export default News