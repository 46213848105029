import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import toast, { Toaster } from 'react-hot-toast';
import Token from "../Assets/images/token.jpg"
import newdetail from "../Assets/images/newdetail.jpg"
import Token1 from "../Assets/images/token1.jpg"
import { getNewsDesDataLimit, getNewsDesDataOnedata, UseContact, useContact, useContract } from '../Hooks/UseBackend';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { backurl } from '../Config/env';
import { validateSubscriper, validateUserContact } from '../Hooks/useValidation';
import logo from '../Assets/images/logo.png'
import newsimg from '../Assets/images/newsimg.png'
const Newsdetails = () => {

    const [newsdata, setnewsdata] = useState({})
    const [newsdesdata, setnewsdesdata] = useState([])
    const [name, setname] = useState("");
    const [Email, setEmail] = useState("");
    const [Comments, setComments] = useState("");
    const [website, setwebsite] = useState("");
    const [errors, seterrors] = useState({})

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        var newsid = window.location.pathname.split("/")[2];
        const data = await getNewsDesDataOnedata(newsid);

        setnewsdata(data)

        const newsLimit = await getNewsDesDataLimit();
        setnewsdesdata(newsLimit)

    }



    const onSubmit = async (e) => {

        e.preventDefault();
        const UserData = {
            name: name,
            Email: Email,
            Comments: Comments,
            website: website,



        };

        const { errors, isValid } = await validateSubscriper(UserData);
        if (!isValid) {

            seterrors(errors)
        }
        else {

            await UseContact(UserData)

        }



    };
    return (
        <div className="news">
            <Header />
            <div><Toaster /></div>
            <div className='newshead'>



                <div className='newdetail'>
                    <img src={backurl + `/Images/${newsdata && newsdata?.logoURI}`} />
                    {/* <img src={newsimg} /> */}
                </div>

            </div>
            <div className='container container-theme'>

                <div className='row'>

                    <div className='col-lg-9 col-md-7 col-12'>
                        <div className='newdetailsec'>
                            <h2>{newsdata?.Title}</h2>




                            <p className='textconete'>
                                <b> {newsdata?.Heading}</b>

                            </p>
                            <p className='textconete'>
                                {ReactHtmlParser(newsdata?.Description)}

                            </p>
                            {newsdata?.NewsLink === null || newsdata?.NewsLink === "" ? <></> :
                                <div className='vdieo_hide no-bord'>
                                    <iframe src={newsdata?.NewsLink}>
                                    </iframe>

                                </div>}

                            {newsdata?.VimeoLink === "" || newsdata?.VimeoLink === null ? <></> :
                                <div className='vdieo_hide'>
                                    <iframe src={newsdata?.VimeoLink}>
                                    </iframe>



                                </div>}

                        </div>

                    </div>
                    <div className='col-lg-3 col-md-5 col-12'>
                        <div className='recent_post'>
                            <h2>Recent Post</h2>
                            {newsdesdata && newsdesdata.map((item) =>
                            (item.status == true ?
                                <div className='recentblogdet'>

                                    <div className='recentdeis'>
                                        <img src={backurl + `/Images/${item?.logoURI}`} />


                                    </div>


                                    <a href={`/newsdetails/${item._id}`}>{item?.Title}</a>




                                </div> : <></>)
                            )}


                        </div>
                        {/* {newsdata?.NewsLink === "" ?<></>:
                    <div className='vdieo_hide'>
                  <iframe src={newsdata?.NewsLink}>
                  </iframe>
                            
                        
                
                    </div>}  */}

                        {/* {newsdata?.VimeoLink === "" ?<></>:
                    <div className='vdieo_hide'>
                  <iframe src={newsdata?.VimeoLink}>
                  </iframe>
                            
                        
                
                    </div>}  */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Newsdetails