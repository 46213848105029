import React, { useEffect, useState ,useRef } from 'react'
import Footer from './Footer'
import Header from './Header'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import kindimg from "../Assets/images/kindimg.png"
import gradimg from "../Assets/images/grad_element.png"
import censorship from "../Assets/images/censorship.png"
import doesit from "../Assets/images/doesit.png"
import aiimg from "../Assets/images/ai.png"
import locker from "../Assets/images/locker.png"

import playicon from "../Assets/images/playicon.png"
import dashimg from "../Assets/images/dashimg.png"
import step1 from "../Assets/images/step1.png"
import step2 from "../Assets/images/step2.png"
import step3 from "../Assets/images/step3.png"
import banner_grad from "../Assets/images/banner_grad.png"
import { getAboutData, getFeatureData, getFeaturedesData, getLaunchpadData, getNewsDesData, getRoadmapdesData, getSettingsData } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';
import { UsegetsingleSaleInfo } from '../Hooks/useContract';
import { FaStar } from "react-icons/fa";

import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
const Land = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleClick1 = () => {
    ref1.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const [aboutdata, setaboutdata] = useState([])
  const [featuredata, setfeaturedata] = useState([])
  const [featuredesdata, setfeaturedesdata] = useState([])

  const [roadmapdata,setroadmapdata] = useState([])
  const [testmony, setTestmony] = useState([


  {

    desc :"The ai-r token is an innovative project that prioritizes health. By utilizing blockchain technology and artificial intelligence, it securely manages individual health data and provides optimal lifestyle support. Privacy is also ensured, allowing for safe participation. This token holds the opportunity for health and longevity." ,
    name: "Emilia García"
  },

  {

    desc :"It's wonderful that we can record our health data on the blockchain from birth with the ai-r token. Based on this valuable data, artificial intelligence will recommend the optimal lifestyle habits. This project has the potential to prevent diseases and significantly extend our healthy lifespan.",
    name: "Yuki Tanaka "
  },
  {

    desc :"I've always been worried about high medical expenses, but with the ai-r token, I can enroll in blockchain-based health insurance, which should significantly reduce my medical costs. There are also tax incentives, I heard. Holding this token seems to offer both health and financial benefits for life.",
    name: " Mohammad Ali"
  },
  {

    desc :"Looking at the ai-r token's roadmap, I'm excited by the dream-like future it depicts. Using the fatigue-recovery sleep capsules could lay the foundation for a healthy, long life. If the project progresses steadily, living beyond 100 years could become the norm. We might be holding the key to conquering aging.",
    name: "Olivia Nguyen"
  },
  {

    desc :"The ai-r token's vision is for artificial intelligence to determine the optimal nutrients based on data like our DNA and automatically supply them. This eliminates health concerns and enables us to maintain a youthful, active physique for a very long time. Token holders are undoubtedly privileged with opportunities for health and longevity.",
    name: "Luca Rossi "
  },
  {

    desc :"The ai-r token contributes to an innovative blockchain-based health management system. We can manage our health information without surveillance from anyone while ensuring complete privacy protection. Trustworthy data is utilized, and cutting-edge AI fully supports our lifestyle for health and longevity. I'm convinced this is the dream system.",
    name: "Samantha Wilson"
  },

  {

    desc :"I've been sickly since birth, but learning about the ai-r token gave me hope. With advice from AI based on my genetic information, I can develop healthy lifestyle habits suited to my constitution. Thanks to this token that prioritizes health first, I too now have a possibility of achieving health and longevity.",
    name: "Arjun Singh"
  },
  {

    desc :"For me, struggling with obesity, the ai-r token offers an unimaginable blessing. AI will supposedly create the optimal diet and exercise routines based on data like my DNA, providing advice tailored to my genetic makeup. A system that optimizes health according to one's innate constitution is truly revolutionary.",
    name: "Mikhail Ivanov"
  },

  {

    desc :"I purchased the ai-r token because I was drawn to its vision of health and longevity. As the project progresses, we'll apparently be able to recover from fatigue quickly using sleep capsules. I should be able to live an energetic, stress-free life every day. I've long struggled with health issues, but this token gives me hope that my dreams can become reality",
    name: "Sarah Thompson "
  },

  {

    desc :"The ai-r token has given me a chance to regain my health. I've been suffering from chronic fatigue and pain, but by having AI analyze my health data, I can find the optimal lifestyle rhythm and supplements. Continuing to hold this token offers the possibility of a complete health recovery. It's my beacon of hope for health and longevity.",
    name: "João Almeida"
  },










  ])

  const [walletimg, setWalletimg] = useState([{
    img: require('../Assets/images/sites/abra.png')
  },
  {
    img: require('../Assets/images/sites/binance_labs.png')
  },
  {
    img: require('../Assets/images/sites/crypto.com-capital.png')
  },
  {
    img: require('../Assets/images/sites/defialliance.png')
  },
  {
    img: require('../Assets/images/sites/hashkey.png')
  },
  {
    img: require('../Assets/images/sites/L1D.png')
  },
  {
    img: require('../Assets/images/sites/maelstrom.png')
  },
  {
    img: require('../Assets/images/sites/mechanism.png')
  },
  {
    img: require('../Assets/images/sites/signum.png')
  },
  {
    img: require('../Assets/images/sites/spartan.png')
  },
])
  const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])
  const [singledata1, setsingledata1] = useState({})
  const [singledata2, setsingledata2] = useState({})
  const [singledata3, setsingledata3] = useState({})
   const [newsdesdata, setnewsdesdata] = useState([])
  const responsive1 = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  }
  const responsive2 = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1200: {
      items: 3,
    },
  }
  useEffect(() => {
    getData();

  }, [])


  const getData = async () => {

    var data = await getAboutData();
    setaboutdata(data)
    var feature = await getFeatureData();
    console.log("feature",feature);
    setfeaturedata(feature);
    var featruedes = await getFeaturedesData();
   
    setfeaturedesdata(featruedes)
    var roadmapdes = await getRoadmapdesData();
    setroadmapdata(roadmapdes)
    const newsdes = await getNewsDesData();
    // setnewsdesdata(newsdes)

    var data = await getLaunchpadData();

    settotalLaunchPadlength(data)
    var singledata = await UsegetsingleSaleInfo(data[0]?.presaleAddress);
    setsingledata1(singledata);
    var singledata2 = await UsegetsingleSaleInfo(data[1]?.presaleAddress);

    setsingledata2(singledata2);
    var singledata3 = await UsegetsingleSaleInfo(data[2]?.presaleAddress);
    setsingledata3(singledata3);


  }
  return (
    <div className='land' ref={ref1}>
      <Header />
      <div className='container container-theme'>
        <section className='banner'>
          <div className='row banner_row'>
            <div className='col-12'>
              {/* <div className='ban-cont'>
                    <p className='themeclr'>{aboutdata && aboutdata[0]?.Title}</p>
                    <h1 className='heading'>{aboutdata && aboutdata[0]?.Heading}</h1>
                    <p>{aboutdata && aboutdata[0]?.Description}</p>
                   <a target='blank' href="https://labelm.io/launchpad">    <button className='btn getstarted'>Get Started</button></a>
                  </div> */}
              <div className='ban-cont'>
                <img src={banner_grad} alt='banner_grad' className='img-fluid banner_grad' />
                <p className='themeclr'>{featuredata && featuredata[0]?.Title}</p>
                <h1 className='heading'>{featuredata && featuredata[0]?.Heading}</h1>
                <p>{featuredata && featuredata[0]?.Description}</p>
                {/* <a target='blank' className="lear_more_sec" href="/launchpad"><img src={playicon} alt='play' className='play_icon' />   Learn More</a> */}
            
              </div>
            </div>
            <div>
              {/* <ul className='bannerwal_ul'>
                {walletimg.map((e) => (
                  <li><img src={e.img.default} alt='wallet' className='img-fluid' /></li>
                ))}

              </ul> */}
              <div className='row bannerwal_ul'>
              {walletimg.map((e) => (
                <div className='col-sm-4 col-md-3 col-lg-2 text-center'>
                <img src={e.img.default} alt='wallet' className='img-fluid' />
                </div>
              ))}

              </div>
            </div>
            <div onClick={handleClick} className='rollerdownsec'>
                  <div className='imgrollsec'>
                  {/* <img src={roller} className='img-fluid rollerimg'/> */}
                  <FaArrowDown  fill='#fff'/>
                  </div>
                  
                </div>
          </div>

        </section>

        <section className='splitsec innersec' ref={ref}>
          <div className='cont_sec'>
            <div className='row main_row col_swap'>
              <div className='col-md-5 imgsec'>
                <img src={gradimg} className='gradimg' />
                <img src={backurl + `/Images/${aboutdata && aboutdata[0]?.logoURI}`} className='img-fluid sec_img' alt='kind' />

              </div>
              <div className='offset-md-1 col-md-6 content_sec'>
                <p className='themeclr'>{aboutdata && aboutdata[0]?.Title}</p>
                <h1 className='heading'>{aboutdata && aboutdata[0]?.Heading}</h1>
                <p className='desc'>{aboutdata && aboutdata[0]?.Description}</p>


              </div>

            </div>
          </div>


        </section>
        <section className='dashsec innersec'>
          <div className='cont_sec'>
            {/* <div className='row main_row'>
              <div className='col-12'>
                <p className='themeclr'>Dashboard</p>
                <h1 className='heading'>All you need is here</h1>
                <p className='desc'>AI-R began with the idea that people should be able to monetize any unused network resources, so we’ve set out to revolutionize the residential proxy market by opening it up to everyone. We believe in giving users ownership of the network from day one.</p>

              </div>blog

            </div> */}
            <div className='row img_row mt-4'>
              <div className='col-12'>
                <img src={dashimg} className='img-fluid' alt='dashimg' />

              </div>

            </div>
          </div>


        </section>
        <section className='splitsec innersec'>
          <div className='cont_sec cl_reverse'>
            <div className='row main_row'>

              <div className='col-md-6 content_sec'>
              <p className='themeclr'>{aboutdata && aboutdata[1]?.Title}</p>
                <h1 className='heading'>{aboutdata && aboutdata[1]?.Heading}</h1>
                <p className='desc'>{aboutdata && aboutdata[1]?.Description}</p>


              </div>
              <div className='offset-md-1  col-md-5 imgsec'>
                <img src={gradimg} className='gradimg' />
                <img src={backurl + `/Images/${aboutdata && aboutdata[1]?.logoURI}`} className='img-fluid sec_img' alt='censorship' />

              </div>

            </div>
          </div>


        </section>
        <section className='splitsec innersec'>
          <div className='cont_sec'>
            <div className='row main_row col_swap'>
              <div className='col-md-5 imgsec mt-4'>
                <img src={gradimg} className='gradimg' />
                <img src={backurl + `/Images/${aboutdata && aboutdata[2]?.logoURI}`} className='img-fluid sec_img' alt='kind' />

              </div>
              <div className='offset-md-1 col-md-6 content_sec'>
              <p className='themeclr'>{aboutdata && aboutdata[2]?.Title}</p>
                <h1 className='heading'>{aboutdata && aboutdata[2]?.Heading}</h1>
                <p className='desc'>{aboutdata && aboutdata[2]?.Description}</p>
                <div className='btnsec_started'>
                  <a target='blank' className='btn-overall' href="/launchpad">  Get Started</a>
                </div>

              </div>

            </div>
          </div>


        </section>
        <section className='splitsec innersec'>
          <div className='cont_sec cl_reverse'>
            <div className='row main_row '>

              <div className=' col-md-6 content_sec'>
              <p className='themeclr'>{aboutdata && aboutdata[3]?.Title}</p>
                <h1 className='heading'>{aboutdata && aboutdata[3]?.Heading}</h1>
                <p className='desc'>{aboutdata && aboutdata[3]?.Description}</p>
              </div>
              <div className='offset-md-1 col-md-5 imgsec'>
                <img src={gradimg} className='gradimg' />
                <img src={backurl + `/Images/${aboutdata && aboutdata[3]?.logoURI}`} className='img-fluid sec_img' alt='ai' />

              </div>

            </div>
          </div>


        </section>
        <section className='splitsec innersec'>
          <div className='cont_sec'>
            <div className='row main_row col_swap'>
              <div className='col-md-5 imgsec'>
                <img src={gradimg} className='gradimg' />
                <img src={backurl + `/Images/${aboutdata && aboutdata[4]?.logoURI}`} className='img-fluid sec_img' alt='locker' />

              </div>
              <div className='offset-md-1 col-md-6 content_sec'>
              <p className='themeclr'>{aboutdata && aboutdata[4]?.Title}</p>
                <h1 className='heading'>{aboutdata && aboutdata[4]?.Heading}</h1>
                <p className='desc'>{aboutdata && aboutdata[4]?.Description}</p>


              </div>

            </div>
          </div>


        </section>
        <section className='dashsec testmony_sec innersec'>
          <div className='cont_sec'>
            <div className='row main_row'>
              <div className='col-12'>
              <p className='themeclr'>{featuredata && featuredata[3]?.Title}</p>
                <h1 className='heading'>{featuredata && featuredata[3]?.Heading}</h1>
                <p>{featuredata && featuredata[3]?.Description}</p>

              </div>

            </div>
            <div className='row img_row mt-4'>
              <OwlCarousel items={3}
                className="owl-theme"
                loop={true}
                nav={true}
                center={true}
                margin={0} autoplay={true}
                responsive={responsive2}
                dots={false}
              >
                {testmony.map((e) => (
                  <div className='item'>
                    <div className='test_card'>
                      <p className='name'>{e.name}</p>
                      {/* <p className='cname'>{e.company}</p> */}
                      <div className='text-center'>
                        {Array.from(Array(5).keys(1)).map(() => (
                          <FaStar fontSize={20}  fill=' #CBFD01'/>
                        ))}
                      </div>
                      <p className='desc'>{e.desc}</p>
                    </div>
                  </div>
                ))}

              </OwlCarousel>

            </div>
          </div>


        </section>
        <section className='dashsec easy_earnsec innersec'>
          <div className='cont_sec'>
            <div className='row main_row mb-4 '>
              <div className='col-12'>
                <p className='themeclr'>{featuredesdata && featuredesdata[0]?.Title}</p>
                <h1 className='heading'>{featuredesdata && featuredesdata[0]?.Heading}</h1>
                <p className='desc'>{featuredesdata && featuredesdata[0]?.Description}</p>
                <div className='btnsec_started'>
                  <a target='blank' className='btn-overall' href="/launchpad">  Get Started</a>
                </div>
              </div>

            </div>
            <div className='row img_row mt-5'>
              <div className='col-lg-4 colsec'>
                <img src={backurl + `/Images/${featuredesdata && featuredesdata[0]?.logoURI1}`} className='stepimg img-fluid' />
                <p className='head mt-4'>{featuredesdata && featuredesdata[0]?.Heading1}</p>
                <p className='minihead'>{featuredesdata && featuredesdata[0]?.Description1}</p>
              </div>
              <div className='col-lg-4 colsec'>
                <img src={backurl + `/Images/${featuredesdata && featuredesdata[0]?.logoURI2}`} className='stepimg img-fluid' />
                <p className='head mt-4'>{featuredesdata && featuredesdata[0]?.Heading2}</p>
                <p className='minihead'>{featuredesdata && featuredesdata[0]?.Description2}</p>
              </div>
              <div className='col-lg-4 colsec'>
                <img src={backurl +`/Images/${featuredesdata && featuredesdata[0]?.logoURI3}`} className='stepimg img-fluid' />
                <p className='head mt-4'>{featuredesdata && featuredesdata[0]?.Heading3}</p>
                <p className='minihead'>{featuredesdata && featuredesdata[0]?.Description3}</p>
              </div>
            </div>
          </div>


        </section>
        <section className='blogsec innersec'>
          <div className='cont_sec'>
            <div className='row main_row mb-4'>
              <div className='col-lg-6'>
                <p className='themeclr'>{featuredata && featuredata[2]?.Title}</p>
                <h1 className='heading'>{featuredata && featuredata[2]?.Heading}</h1>
              </div>
              <div className='col-lg-6'>
                <p className='desc mb-0'>{featuredata && featuredata[2]?.Description}</p>

              </div>

            </div>
            {/* {blogtrend.map((e) => (
              <div className='row blogrow'>
                <div className='col-lg-8 leftsec'>

                  <div>
                    <div className='imgsec'>
                      {console.log(e.blogimg, "sss")}
                      <img src={e?.blogimg.default} alt='trend' className='img-fluid maintrendimg' />
                    </div>
                    <div className='trendcard ms-auto'>
                      <p className='catname'>{e.catname}</p>
                      <p className='title'>{e.blogtitle}</p>
                      <p className='desc'>{e.blogdesc}</p>
                    </div>
                  </div>


                </div>
                <div className='col-lg-4 rightsec'>
                  {e.subtrend.map((e) => (
                    <>
                      <div className='subcard'>
                        <img src={e.img.default} alt='img' className='img-fluid subtrendimg' />
                        <p className='catname'>{e.catname}</p>
                        <p className='titlename'>{e.blogtitle}</p>
                      </div>
                    </>

                  ))}


                </div>

              </div>
            ))} */}

          </div>


        </section>

        <section className=" themesec roadmap resp991 innersec pt-md-5" id="roadmap">
        <div onClick={handleClick1} className='rollerdownsec'>
                  <div className='imgrollsec'>
                  <FaArrowUp  fill='#fff'/>
                  </div>
                  
                </div>
          <div className="">
            <div className="container container_custom">
              <div className='ban-cont text-center'>
              
                <p className='themeclr'>{featuredata && featuredata[1]?.Title}</p>
                <h1 className='innerheading'>{featuredata && featuredata[1]?.Heading}</h1>
                <p className='smallp'>{featuredata && featuredata[1]?.Description}</p>

              </div>

              <div className="slider_sec_1 fullinerelative">
                <div className='fullline'></div>
                <OwlCarousel items={3}
                  className="owl-theme"
                  loop={false}
                  nav={false}
                  margin={0} autoplay={false} responsive={responsive1} dots={false}>
                  {roadmapdata && roadmapdata.map((item) =>
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>{item?.Heading}</h6>
                        <p>{item?.Description}</p>

                      </div>
                    </div>
                  )}

                

                </OwlCarousel>
              </div>

            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Land